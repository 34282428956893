<template>
  <div class="passport">
    <h1>Pub Crawl Locations</h1>
    <v-row>
      <template v-for="(brew, i) in breweries">
        <v-col :key="i" class="d-flex child-flex" cols="4">
          <router-link :to="{ name: 'Brew', params: { id: brew.objectId } }">
            <div class="pub">
              <img
                :src="require('@/assets/images/pubs/' + brew.logoURL)"
                aspect-ratio="1"
                style="
                  border-radius: 5px;
                  background: black;
                  width: 100px;
                  height: 100px;
                "
                :href="brew.siteURL"
              /><img
                class="shamrock"
                v-if="brew.checked == true"
                :src="require('@/assets/images/shamrock.png')"
              /></div
          ></router-link>
        </v-col>
      </template>
    </v-row>
    <h2 class="scan">Use our scanner below to check-in at each location!</h2>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import { results } from '@/data.js'
export default {
  // data() {
  //   return {
  //     breweries: results.name
  //   }
  // },
  methods: {
    async redeem(key) {
      await this.$store.dispatch("brew/getBrew", key);
      this.$router.push({ name: "Brew", params: { id: key } });
    },
  },
  mounted() {
    this.$store.dispatch("brew/getBreweries");
    if (this.$route.params.id != null) {
      console.log("We have an id!");
      this.redeem(this.$route.params.id);
    }
  },
  computed: {
    ...mapGetters("brew", ["breweries"]),
  },
};
</script>

<style>
.pub {
  position: relative;
  width: 100px;
  height: 100px;
}
.shamrock {
  width: 50px;
  height: 50px;
  position: absolute;
  left: 0px;
  top: 0px;
}
h2.scan {
  position: absolute;
  bottom: 10px;
  width: 100%;
  text-align: center;
}
.passport {
  position: relative;
  min-height: 90vh;
}
</style>